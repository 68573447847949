var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "application",
        {
          "application--local": _vm.LOCAL || _vm.WEB_VITALS,
          "application--with-background":
            _vm.withBackground || _vm.LOCAL || _vm.WEB_VITALS,
          "application--se": _vm.SWEDEN,
          "application--no": _vm.NORWAY,
          "application--fi": _vm.FINLAND,
          "application--one-column": _vm.oneColumn,
          "application--on-beige-background":
            _vm.onBeigeBackground || _vm.onArticlePage || _vm.onPartnerPage,
          "application--on-article-page": _vm.onArticlePage,
          "application--on-partner-page": _vm.onPartnerPage,
          "application--on-campaign-page": _vm.onCampaignPage,
        },
      ],
    },
    [
      _c("lazy-hydrate", { attrs: { "when-idle": "" } }, [
        _c(
          "div",
          { staticClass: "above-steps" },
          [
            _vm.showElement("title")
              ? _c("h3", { domProps: { innerHTML: _vm._s(_vm.formTitle) } })
              : _vm._e(),
            _vm._v(" "),
            _vm.showReApply ? _c("re-apply", { key: "re-apply" }) : _vm._e(),
            _vm._v(" "),
            _vm.showTranslation
              ? _c("language-translation", {
                  key: "language-translation",
                  attrs: {
                    "on-beige-background":
                      _vm.onBeigeBackground ||
                      _vm.onArticlePage ||
                      _vm.onPartnerPage,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.showCalculator
              ? _c("calculator", { key: "calculator" })
              : _vm._e(),
            _vm._v(" "),
            _vm.showLeadInfo ? _c("lead-info", { key: "lead-info" }) : _vm._e(),
            _vm._v(" "),
            _vm.showErrors
              ? _c("field-errors", { key: "field-errors" })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "lazy-hydrate",
        { attrs: { "when-idle": "" } },
        [
          _c(
            "zg-transition-block",
            [
              _vm.showForm
                ? _c("steps", {
                    key: "steps",
                    attrs: {
                      "on-beige-background":
                        _vm.onBeigeBackground ||
                        _vm.onArticlePage ||
                        _vm.onPartnerPage,
                    },
                    on: { "changed-step": _vm.changedStep },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("lazy-hydrate", { attrs: { "when-idle": "" } }, [
        _c(
          "div",
          { staticClass: "below-steps" },
          [
            _c("buttons", {
              key: "buttons",
              attrs: {
                "submit-label": _vm.submitLabel,
                "on-article-page": _vm.onArticlePage,
                "on-partner-page": _vm.onPartnerPage,
              },
              on: { submit: _vm.submit },
            }),
            _vm._v(" "),
            _c("terms", {
              key: "terms",
              attrs: {
                "on-campaign-page": _vm.onCampaignPage,
                "submit-label": _vm.submitLabel,
              },
            }),
            _vm._v(" "),
            _vm.showUsps
              ? _c("usps", { key: "usps", attrs: { usps: _vm.content.usps } })
              : _vm._e(),
            _vm._v(" "),
            _vm.showDisclaimer
              ? _c("disclaimer", { key: "disclaimer" })
              : _vm._e(),
            _vm._v(" "),
            _c("sticky-cta", {
              attrs: {
                label: _vm.submitLabelStickyCta,
                target: ".application",
              },
            }),
            _vm._v(" "),
            _vm.showAutoSbuOptIn
              ? _c("auto-sbu-opt-in-modal", { key: "auto-sbu-opt-in-modal" })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.showDebug ? _c("debug-menu", { key: "debug-menu" }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }