<template>
  <div
    :class="['application', {
      'application--local': LOCAL || WEB_VITALS,
      'application--with-background': withBackground || LOCAL || WEB_VITALS,
      'application--se': SWEDEN,
      'application--no': NORWAY,
      'application--fi': FINLAND,
      'application--one-column': oneColumn,
      'application--on-beige-background': onBeigeBackground || onArticlePage || onPartnerPage,
      'application--on-article-page': onArticlePage,
      'application--on-partner-page': onPartnerPage,
      'application--on-campaign-page': onCampaignPage
    }]"
  >
    <lazy-hydrate when-idle>
      <div class="above-steps">
        <h3
          v-if="showElement('title')"
          v-html="formTitle"
        />

        <re-apply
          v-if="showReApply"
          key="re-apply"
        />

        <language-translation
          v-if="showTranslation"
          key="language-translation"
          :on-beige-background="onBeigeBackground || onArticlePage || onPartnerPage"
        />

        <calculator
          v-if="showCalculator"
          key="calculator"
        />

        <lead-info
          v-if="showLeadInfo"
          key="lead-info"
        />

        <field-errors
          v-if="showErrors"
          key="field-errors"
        />
      </div>
    </lazy-hydrate>

    <lazy-hydrate when-idle>
      <zg-transition-block>
        <steps
          v-if="showForm"
          key="steps"
          :on-beige-background="onBeigeBackground || onArticlePage || onPartnerPage"
          @changed-step="changedStep"
        />
      </zg-transition-block>
    </lazy-hydrate>

    <lazy-hydrate when-idle>
      <div class="below-steps">
        <buttons
          key="buttons"
          :submit-label="submitLabel"
          :on-article-page="onArticlePage"
          :on-partner-page="onPartnerPage"
          @submit="submit"
        />

        <terms
          key="terms"
          :on-campaign-page="onCampaignPage"
          :submit-label="submitLabel"
        />

        <usps
          v-if="showUsps"
          key="usps"
          :usps="content.usps"
        />

        <disclaimer
          v-if="showDisclaimer"
          key="disclaimer"
        />

        <sticky-cta
          :label="submitLabelStickyCta"
          target=".application"
        />

        <auto-sbu-opt-in-modal
          v-if="showAutoSbuOptIn"
          key="auto-sbu-opt-in-modal"
        />
      </div>
    </lazy-hydrate>

    <debug-menu
      v-if="showDebug"
      key="debug-menu"
    />
  </div>
</template>

<script>
  import { hasProp, historyPush } from '@ocp-zmarta/zmarta-cl'
  import LazyHydrate from 'vue-lazy-hydration'
  import { mapActions, mapGetters } from 'vuex'
  import { highlightTitle } from '../../../../utils/dom'

  export default {
    name: 'SingleStep',
    components: {
      LazyHydrate,
      AutoSbuOptInModal: () => import(/* webpackChunkName: 'elements/cl/misc/auto-sbu-opt-in-modal' */
        '../../../elements/cl/misc/AutoSbuOptInModal.vue'),
      Buttons: () => import(/* webpackChunkName: 'application/cl/single-step/shared/buttons' */
        './_shared/Buttons.vue'),
      Calculator: () => import(/* webpackChunkName: 'application/cl/single-step/shared/calculator' */
        './_shared/Calculator'),
      Disclaimer: () => import(/* webpackChunkName: 'application/cl/single-step/shared/disclaimer' */
        './_shared/Disclaimer.vue'),
      FieldErrors: () => import(/* webpackChunkName: 'application/cl/single-step/shared/field-errors' */
        './_shared/FieldErrors'),
      LanguageTranslation: () => import(/* webpackChunkName: 'elements/cl/misc/language-translation' */
        '../../../elements/cl/misc/LanguageTranslation'),
      LeadInfo: () => import(/* webpackChunkName: 'application/cl/single-step/shared/lead-info' */
        './_shared/LeadInfo.vue'),
      Steps: () => import(/* webpackChunkName: 'application/cl/single-step/shared/steps' */
        './_shared/Steps.vue'),
      StickyCta: () => import(/* webpackChunkName: 'application/cl/single-step/shared/sticky-cta' */
        './_shared/StickyCta.vue'),
      Terms: () => import(/* webpackChunkName: 'application/cl/single-step/shared/terms' */
        './_shared/Terms.vue'),
      Usps: () => import(/* webpackChunkName: 'elements/shared/misc/usps' */
        '../../../elements/_shared/misc/Usps.vue'),
      ReApply: () => import(/* webpackChunkName: 'elements/cl/misc/re-apply' */
        '../../../elements/cl/misc/ReApply'),
      DebugMenu: () => import(/* webpackChunkName: 'elements/cl/misc/debug' */
        '../../../elements/cl/misc/DebugMenu'),
      ZgTransitionBlock: () => import(/* webpackChunkName: 'zc/zg-transition-block' */
        '@zc/components/ZgTransitionBlock/ZgTransitionBlock')
    },
    props: {
      onBeigeBackground: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      showErrors: false
    }),
    computed: {
      ...mapGetters('content', ['getContent']),
      ...mapGetters('router', ['getRoute', 'getQuery']),
      ...mapGetters('experiments', ['getExperiments']),
      ...mapGetters('features', ['getFeatures']),
      ...mapGetters('userAttributes', ['getAttributes']),
      ...mapGetters('misc', ['getIsMobile', 'getInternalAccess']),
      ...mapGetters('form', ['getForm']),
      ...mapGetters('cl/formAlternatives', ['getFormAlternatives']),
      ...mapGetters('cl/application', ['getApplicationEvents', 'getShowForm']),
      ...mapGetters('cl/misc', ['getMisc']),
      ...mapGetters('cl/formSteps', ['currentStep', 'currentStepName']),
      content () {
        return this.getContent?.application?.singleStep ?? {}
      },
      withBackground () {
        return hasProp(this.getQuery, 'background')
      },
      showElements () {
        return this.getQuery?.['show-elements']?.split(',') || []
      },
      hideElements () {
        return this.getQuery?.['hide-elements']?.split(',') || []
      },
      formTitle () {
        if (this.BROKER && this.FINLAND) return highlightTitle({ market: this.MARKET, text: this.content.title }) || ''
        return this.content?.title || ''
      },
      oneColumn () {
        return hasProp(this.getQuery, 'one-column')
      },
      onArticlePage () {
        return hasProp(this.getQuery, 'on-article-page')
      },
      onPartnerPage () {
        return hasProp(this.getQuery, 'on-partner-page')
      },
      onCampaignPage () {
        return hasProp(this.getQuery, 'on-campaign-page')
      },
      isDesktopUser () {
        return this.$store?.state?.features?.isdesktopuser?.enabled
      },
      showExitConfirm () {
        return this.isDesktopUser && !this.TESTCAFE && this.FINLAND
      },
      isAddCoApplicantLead () {
        return this.getForm?.global?.leadType?.value === 'addCoApplicant'
      },
      isContinueApplicationLead () {
        return this.getForm?.global?.leadType?.value === 'continueApplication'
      },
      showForm () {
        return this.getShowForm
      },
      showCalculator () {
        return this.currentStepName !== 'thankYou'
      },
      showAutoSbuOptIn () {
        // todo: replace with feature if experiment variation wins
        return (
          this.getExperiments?.['cl-opt-in']?.activated &&
          this.getExperiments?.['cl-opt-in']?.variationKey === 'variation'
        )
      },
      showReApply () {
        return (
          this.HAS_RE_APPLY &&
          !this.onArticlePage &&
          !this.onPartnerPage &&
          !this.onCampaignPage &&
          !this.getApplicationEvents?.reApplied &&
          this.getExperiments?.['cl-form-re-apply-3']?.activated &&
          this.getExperiments?.['cl-form-re-apply-3']?.variationKey === 'variation'
        )
      },
      showTranslation () {
        return (
          this.getFeatures?.translations?.enabled &&
          !this.hideElement('translation') &&
          !this.onArticlePage &&
          !this.onPartnerPage &&
          !this.onCampaignPage
        )
      },
      showUsps () {
        return (
          !this.hideElement('usps') &&
          !this.onCampaignPage
        )
      },
      showDisclaimer () {
        return !this.hideElement('disclaimer')
      },
      showLeadInfo () {
        return this.isContinueApplicationLead || this.isAddCoApplicantLead
      },
      submitLabelStickyCta () {
        if (this.onCampaignPage && this.SWEDEN && this.currentStep === 1) {
          return this.content?.loanDetails?.nextCampaignPage
        }

        return this.content?.next
      },
      submitLabel () {
        let label = this.content?.[this.currentStepName]?.next

        if (this.onCampaignPage && this.SWEDEN && this.currentStep === 1) {
          label = this.content?.loanDetails?.nextCampaignPage
        }

        if (label?.includes('{purpose}')) {
          const selectedPurpose = this.getForm?.global?.loanPurpose?.value

          if (selectedPurpose) {
            const { label: l } = this.getFormAlternatives?.loanPurposes?.find(purpose => purpose.value === selectedPurpose) || {}
            label = label?.replace('{purpose}', l?.replace(' –', ''))
          } else {
            label = null
          }
        }

        return label || this.getContent?.application?.singleStep?.next
      },
      showDebug () {
        return this.getInternalAccess
      }
    },
    async mounted () {
      await this.activateReApplyTest()

      if (this.getForm?.global?.appNo?.value) {
        window.appNo = this.getForm?.global?.appNo?.value || window.appNo
      }

      setTimeout(() => {
        this.goToInitialStep()
        if (this.showForm) this.onInteracted()
      }, 0)

      this.EVENT_BUS.$on('application:show-invalid-fields', () => this.showInvalidFields())
      this.$emit('loaded')
    },
    beforeDestroy () {
      if (this.showExitConfirm) {
        window.removeEventListener('beforeunload', this.beforeWindowUnload)
      }

      this.EVENT_BUS.$off('application:show-invalid-fields')
    },
    methods: {
      ...mapActions('modal', ['hideModal']),
      ...mapActions('loader', ['hideLoader']),
      ...mapActions('experiments', ['activateExperiment']),
      ...mapActions('messages', ['setSuccessMessage']),
      ...mapActions('misc', ['scrollToElement', 'setInternalAccess']),
      ...mapActions('form', ['validateFields']),
      ...mapActions('cl/application', ['setLoanValues', 'setShowForm']),
      ...mapActions('cl/misc', ['updateDisclaimer']),
      ...mapActions('cl/analytics', ['viewTracking', 'buttonTracking']),
      ...mapActions('cl/formSteps', ['setSteps', 'goToInitialStep', 'goToNextStep', 'setSuccessModal', 'changeStep']),
      showElement (el) {
        return this.showElements?.includes(el)
      },
      hideElement (el) {
        return this.hideElements?.includes(el)
      },
      async submit () {
        if (!this.showForm) {
          await this.buttonTracking({ label: 'continue-form' })
          await this.onInteracted()

          return
        }

        await this.goToNextStep()
      },
      async changedStep () {
        historyPush({ name: 'application', step: this.currentStep })

        if (this.showExitConfirm) {
          window.removeEventListener('beforeunload', this.beforeWindowUnload)
        }

        if (this.currentStep > 1) {
          this.$scrollTo(`#${this.currentStepName}`, 500)
        }

        await this.validateFields()
        await this.hideLoader()
        await this.hideModal()
      },
      async showInvalidFields () {
        this.showErrors = true
        await this.scrollToElement({ element: '.fields-errors', offset: -120 })
      },
      beforeWindowUnload (e) {
        e.preventDefault()
        e.returnValue = ''
      },
      async onInteracted () {
        await this.setInternalAccess()
        await this.activateOptInTest()
        await this.setShowForm(true)
      },
      async activateReApplyTest () {
        if (
          !this.HAS_RE_APPLY ||
          this.getApplicationEvents?.reApplied ||
          !this.ZMARTA ||
          this.TESTCAFE
        ) return

        const activation = await this.activateExperiment('cl-form-re-apply-3')
        if (!activation?.variationKey) return

        const label = this.showReApply
          ? 're-apply-seen'
          : 're-apply-not-seen'

        setTimeout(() => {
          this.viewTracking({ label })
        }, 1000)
      },
      async activateOptInTest () {
        const activatedExperiment = await this.activateExperiment('cl-opt-in')
        if (!activatedExperiment) return

        this.ZGA.event.experiment.optimizely({
          experimentId: activatedExperiment.id,
          experimentKey: activatedExperiment.experimentKey,
          variationId: activatedExperiment.variationsMap?.[activatedExperiment.variationKey]?.id,
          variationKey: activatedExperiment.variationKey
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  $triangle-size: rem(16px);
  $gutter: rhythm(1.2);
  $input-box-gutter: $gutter * 0.8;

  .application {
    position: relative;

    &--local {
      padding: rhythm(small);
      background-color: color(white);
      box-shadow: box-shadow();

      @include mq(medium) {
        width: rem(560px) + (rhythm(medium) * 2);
        margin: 0 auto;
        padding: rhythm(medium);
        border-radius: radius(box);
      }

      &.application--on-beige-background {
        background-color: color(beige);
      }
    }

    &--with-background {
      padding: rhythm(small);
      background-color: color(white);

      @include mq(medium) {
        padding: rhythm(medium);
        margin: rhythm(large) auto;
      }
    }

    &--se {
      min-height: rem(456px);

      &.application--local {
        min-height: rem(538px);
      }

      &.application--on-campaign-page {
        min-height: rem(256px);
      }
    }

    &--no {
      min-height: rem(456px);

      &.application--local {
        min-height: rem(538px);
      }

      &.application--on-campaign-page {
        min-height: rem(256px);
      }
    }

    &--fi {
      min-height: rem(496px);

      &.application--local {
        min-height: rem(574px);
      }

      &.application--on-campaign-page {
        min-height: rem(256px);
      }
    }

    :deep(.zg-usps--direction-horizontal) {
      @include mq(large) {
        gap: rhythm(small);
      }
    }
  }

  :deep(.row) {
    @include mq(medium) {
      display: flex;
      margin-left: -$gutter;
      margin-right: -$gutter;
    }

    & > div {
      position: relative;

      @include mq(medium) {
        width: 50%;
        padding-left: $gutter;
        padding-right: $gutter;
      }
    }
  }

  :deep(.row--bottom) {
    align-items: flex-end;
  }

  :deep(.row--full-width) {
    @include mq(medium) {
      & > div {
        width: 100%;
      }
    }
  }

  :deep(.form-input--radio) {
    @include mq(medium) {
      .zg-form-element {
        width: 100%;
      }

      .zg-radio {
        width: 100%;
        flex-direction: row;
        align-items: center;
        gap: $gutter * 2;

        legend {
          width: 50%;
          margin: 0;
        }

        .wrapper {
          width: 50%;
        }

        .button {
          flex-grow: 1;
        }
      }
    }
  }

  :deep(.input-box) {
    position: relative;
    background: lighten(color(gray-200), 15%);
    padding: $gutter $input-box-gutter $input-box-gutter;
    border-radius: radius(box);
    margin: (- $input-box-gutter) (- $input-box-gutter) rhythm(small);

    .zg-form-element {
      margin: 0 0 $gutter;
    }

    .form-input--radio,
    .form-input--date {
      margin-top: $gutter * 1.5;
    }
  }

  :deep(.input-box--column) {
    .zg-form-element {
      margin: 0 0 $gutter;
    }

    .form-input:last-child .zg-form-element {
      margin: 0;
    }
  }

  :deep(.input-box--with-arrow::before) {
    position: absolute;
    top: - $triangle-size + rem(4px);
    left: 50%;
    border-left: $triangle-size solid transparent;
    border-right: $triangle-size solid transparent;
    border-bottom: $triangle-size solid lighten(color(gray-200), 15%);
    content: '';
    transform: translateX(-50%);
  }

  :deep(.input-box--with-arrow-right::before) {
    @include mq(medium) {
      left: 75%;
    }
  }

  :deep(.input-box--with-arrow-left::before) {
    @include mq(medium) {
      left: 25%;
    }
  }

  .application--one-column,
  .application--on-article-page,
  .application--on-partner-page {
    &.application--local {
      @include mq(medium) {
        width: rem(400px);
      }
    }

    :deep(.row) {
      flex-direction: column;

      & > div {
        @include mq(medium) {
          width: 100%;
        }
      }
    }

    :deep(.zg-button) {
      @include mq(medium) {
        width: 100%;
      }
    }
  }

  .application--on-beige-background,
  .application--on-article-page,
  .application--on-partner-page {
    :deep(.input-box) {
      background: color(white);
    }

    :deep(.input-box--with-arrow::before) {
      border-bottom: $triangle-size solid color(white);
    }
  }

  .application--on-article-page,
  .application--on-partner-page {
    &.application--local {
      background-color: color(beige);

      @include mq(medium) {
        width: rem(296px) + (rhythm(medium) * 2);
      }
    }

    :deep(.form-input--radio) {
      .zg-radio {
        flex-direction: column;

        legend,
        .wrapper {
          width: 100%;
        }
      }
    }

    :deep(.calculator) {
      .container {
        flex-direction: column-reverse;
      }

      .input {
        min-width: 100%;
        margin: rhythm(small) 0;
      }
    }
  }
</style>
